<template>

  <div class="container">
    <component :is="isMobile ? 'MobileLayout' : 'WebLayout'"></component>
    </div>
  </template>
  
  <script >
    import { onMounted,onBeforeUnmount, ref} from 'vue'
    import WebLayout from './page/web_page.vue'
    import MobileLayout from './page/mobile_page.vue'

    export default {
      components: {
        WebLayout,
        MobileLayout
      },
      setup() {
      const isMobile = ref(false)

      const handleResize = () => {
        isMobile.value = window.innerWidth <= 750
      }

      onMounted(() => {
        window.addEventListener('resize', handleResize)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize)
      })

      handleResize() 

      return {
        isMobile
      }
      }
    }
  
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
  }
  body{
    margin: 0;
    padding: 0;
    margin-top: 0;
  }

  </style>
  